import * as React from "react";

import Navbar from "./components/Navbar";
import RequesterTable from "./components/Table";

const TrainRequester = () => {
  return (
    <>
      <Navbar />
      <RequesterTable />
    </>
  );
};

export default TrainRequester;
